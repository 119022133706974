import styled from '@emotion/styled'

const Signature = styled.span`
  display: inline-block;
  transform: rotate(-8deg);
  font-family: TheWoodlands-Regular;
  font-size: 50px;
  color: ${({ theme }) => theme.black};
  letter-spacing: 0;
`

export { Signature }
