import React from 'react'
import Image from 'gatsby-image'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from '@emotion/styled'
import SEO from '../components/seo'
import { Layout } from '../components/Layout'
import { Column, Container, Row } from '../components/Grid'
import { Paragraph, Bold } from '../components/Text'
import { useStaticQuery, graphql } from 'gatsby'
import { Signature } from '../components/Text/Signature'
import { Header } from '../components/Header'
import { breakPoints } from '../components/Layout/theme'

const Wrapper = styled.div`
  padding: 120px 0;
  @media (max-width: ${breakPoints.sm}) {
    padding: 40px 0;

  @media (max-width: ${breakPoints.xs}) {
    padding: 40px 0;
  }
`

const IndexPage = props => {
  const data = useStaticQuery(graphql`
    {
      tinne: file(relativePath: { eq: "tinne.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pageContent: contentfulSection(slug: { eq: "over-fienefleur" }) {
        content {
          json
        }
      }
    }
  `)

  const imageData = data.tinne.childImageSharp.fluid
  const richTextOptions = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    },
  }
  const pageContent = documentToReactComponents(
    data.pageContent.content.json,
    richTextOptions,
  )

  return (
    <Layout location={props.location}>
      <SEO title="Fienefleur" />
      <Header />
      <Container>
        <Wrapper>
          <Row>
            <Column display={{ md: 8 }}>
              {pageContent}
              <Signature>Tinne</Signature>
            </Column>
            <Column display={{ xs: 6, md: 4 }} offset={{ xs: 3, md: 0 }}>
              <div style={{ marginLeft: 20 }}>
                <Image fluid={imageData} style={{ verticalAlign: 'middle' }} />
              </div>
            </Column>
          </Row>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default IndexPage
