import React from 'react'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { breakPoints } from '../Layout/theme'

const RightArrow = styled.img`
  transition: transform 0.2s ease-in-out;
`

const ButtonLink = styled(Link)``

const Button = styled(BackgroundImage)`
  height: 97px;
  position: relative;
  margin-bottom: 10px;
  background: #ffc2c7;
  background-image: linear-gradient(
    -198deg,
    rgba(239, 156, 104, 0.17) 0%,
    rgba(239, 156, 104, 0) 41%
  );
  background-size: cover;
  border-radius: 5px;
  transition: box-shadow 0.2s ease-in-out;
  @media (max-width: ${breakPoints.xs}) {
    height: 80px;
  }

  & ${ButtonLink} {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: BloggerSans-BoldItalic;
    font-size: 16px;
    color: #a4484f;
    text-decoration: none;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    @media (max-width: ${breakPoints.xs}) {
      padding: 0 12px;
      text-align: center;
      justify-content: center;
      line-height: 1.2;
    }
  }

  & ${RightArrow} {
    @media (max-width: ${breakPoints.xs}) {
      display: none;
    }
  }

  &:hover {
    box-shadow: 0 2px 7px -5px rgba(0, 0, 0, 0.2),
      0 7px 8px -2px rgba(64, 48, 48, 0.21);

    & ${RightArrow} {
      transform: translateX(5px);
    }
  }
`

const CTAButton = ({ children, background, to }) => {
  const { rightArrow } = useStaticQuery(graphql`
    {
      rightArrow: file(relativePath: { eq: "right-arrow.svg" }) {
        publicURL
      }
    }
  `)
  return (
    <Button Tag="div" fluid={background}>
      <ButtonLink to={to}>
        {children}
        <RightArrow src={rightArrow.publicURL} />
      </ButtonLink>
    </Button>
  )
}

export { CTAButton }
