import React from 'react'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Column } from '../Grid'
import { Navigation } from '../Navigation'
import { H1, SubTitle } from '../Title'
import css from '@emotion/css'
import { CTAButton } from '../CTAButton'

const Wrapper = styled(BackgroundImage)`
  min-height: 450px;
`

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      background: file(relativePath: { eq: "header-homepage.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ctaSensitieveCoaching: file(
        relativePath: { eq: "cta-sensitieve-coaching.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ctaMassages: file(relativePath: { eq: "cta-massages.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ctaWorkshops: file(relativePath: { eq: "cta-workshops.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ctaLuminousSoul: file(relativePath: { eq: "cta-luminous-soul.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const background = data.background.childImageSharp.fluid
  const ctaSensitieveCoaching = data.ctaSensitieveCoaching.childImageSharp.fluid
  const ctaLuminousSoul = data.ctaLuminousSoul.childImageSharp.fluid
  const ctaMassages = data.ctaMassages.childImageSharp.fluid
  const ctaWorkshops = data.ctaWorkshops.childImageSharp.fluid

  return (
    <Wrapper Tag="header" fluid={background} critical fadeIn={false}>
      <Container>
        <Row>
          <Navigation />
        </Row>
        <Row>
          <Column
            display={{ md: 7, xs: 12 }}
            offset={{ md: 0, xs: 0 }}
            style={{ paddingTop: 52 }}>
            <H1>Fienefleur is uw persoonlijke gids in groei en bloei. </H1>
            <SubTitle
              css={css`
                padding-top: 16px;
                margin-bottom: 40px;
              `}>
              Herstel uw energie en verminder uw klachten via sensitieve
              coaching of ontspannende massages.
            </SubTitle>
          </Column>
        </Row>
        <Row>
          <Column
            display={{ xs: 6, sm: 6, md: 3 }}
            offset={{ xs: 0, sm: 0, md: 0 }}>
            <CTAButton
              background={ctaSensitieveCoaching}
              to="/coaching-therapie/">
              Coaching & therapie
            </CTAButton>
          </Column>
          <Column
            display={{ xs: 6, sm: 6, md: 3 }}
            offset={{ xs: 0, sm: 0, md: 0 }}>
            <CTAButton background={ctaLuminousSoul} to="/healing/">
              Healing
            </CTAButton>
          </Column>
        </Row>
        <Row>
          <Column
            display={{ xs: 6, sm: 6, md: 3 }}
            offset={{ xs: 0, sm: 0, md: 0 }}>
            <CTAButton background={ctaMassages} to="/massages/">
              Massages
            </CTAButton>
          </Column>
          <Column
            display={{ xs: 6, sm: 6, md: 3 }}
            offset={{ xs: 0, sm: 0, md: 0 }}>
            <CTAButton background={ctaWorkshops} to="/living/">
              Living
            </CTAButton>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  )
}

export { Header }
